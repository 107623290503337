












































































































































.align-items-center{
  .col-md-8{
    .el-checkbox{
      margin-bottom: 0;
    }
  }
}
